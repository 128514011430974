import React from "react";
import { useSelector } from 'react-redux';
import JncButton from "../components/global/JncButton";
import JncSectionTitle from "../components/global/JncSectionTitle";
import jsPDF from "jspdf";
import { format } from 'date-fns';


export default function JncAccountConfirmation({ activeTab }) {
	const imageBaseUrl = useSelector((state) => state.config.image_base_url);
	const clientData = useSelector((state) => state.juno.clientData)
	const whiteLabelLogo = sessionStorage.getItem("logo")
	const cryptoLogo = sessionStorage.getItem("cryptoLogo")
	const activeCurrencyPage = localStorage.getItem("activeCurrencyPage")



	const generatePDF = async () => {
		const imageUrl = activeCurrencyPage === 'fiat' ? whiteLabelLogo : cryptoLogo;

		try {
			const response = await fetch(imageUrl);
			if (!response.ok) {
				throw new Error(`Failed to fetch image: ${response.statusText}`);
			}

			const contentType = response.headers.get("content-type");

			let dataUrl;
			if (contentType.includes("svg")) {
				const svgText = await response.text();
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				const img = new Image();

				img.onload = () => {
					canvas.width = img.width;
					canvas.height = img.height;
					ctx.drawImage(img, 0, 0);
					dataUrl = canvas.toDataURL('image/png');
					createPDF(dataUrl, img.width, img.height);
				};

				img.onerror = () => {
					console.error('Failed to load SVG image into the canvas');
				};

				img.src = 'data:image/svg+xml;base64,' + btoa(svgText);
			} else {
				const img = new Image();
				img.crossOrigin = "Anonymous";
				img.src = imageUrl;
				img.onload = () => {
					createPDF(img, 50, 10);
				};
			}
		} catch (error) {
			console.error('Error generating PDF:', error);
		}
	};

	const createPDF = (dataUrl, imgWidth, imgHeight) => {
		const maxWidth = 50;
		const maxHeight = 10;

		let width = imgWidth;
		let height = imgHeight;

		const aspectRatio = width / height;
		if (width > maxWidth || height > maxHeight) {
			if (width / maxWidth > height / maxHeight) {
				width = maxWidth;
				height = maxWidth / aspectRatio;
			} else {
				height = maxHeight;
				width = maxHeight * aspectRatio;
			}
		}

		const pdf = new jsPDF();
		pdf.addImage(dataUrl, 'PNG', 10, 5, width, height);

		pdf.setFontSize(14);
		pdf.setDrawColor(208, 213, 221);
		pdf.line(10, 18, 200, 18);

		pdf.setTextColor(71, 84, 103).text(format(new Date(clientData.createdAt), 'EEEE do MMMM u'), 10, 25);
		pdf.setTextColor(52, 64, 84).setFont(undefined, 'bold').text(clientData.name, 10, 35).setFont(undefined, 'normal').setTextColor(71, 84, 103);

		if (clientData.address) {
			pdf.text(clientData.address, 10, 43);
		}
		if (clientData.city) {
			pdf.text(clientData.city, 10, 50);
		}
		if (clientData.postCode) {
			pdf.text(clientData.postCode, 10, 57);
		}
		if (clientData.country) {
			pdf.text(clientData.country, 10, 64);
		}
		pdf.text(`Dear ${clientData.name},`, 10, 83);
		pdf.setTextColor(52, 64, 84).setFont(undefined, 'bold').text('Re: Account Confirmation Letter', 10, 92).setFont(undefined, 'normal');

		const lines = pdf.splitTextToSize(
			`Juno Pay Ltd (trading as Juno money) can confirm that ${clientData.name} holds an account with our financial institution.`,
			190
		);

		let textY = 101;
		lines.forEach((line, index) => {
			pdf.text(line, 10, textY + index * 7);
		});

		pdf.text('Account Reference: ', 10, 119).setTextColor(52, 64, 84).setFont(undefined, 'bold').text(`${clientData.accountNumber}.`, 55, 119).setFont(undefined, 'normal');

		pdf.setTextColor(71, 84, 103).text('Please do not hesitate to contact our Customer Services team if you have any questions', 10, 131);
		pdf.text('in relation to your account.', 10, 137);

		pdf.text('Yours sincerely,', 10, 148);
		pdf.setTextColor(52, 64, 84).setFont(undefined, 'bold').text('BECKY LEVERS', 10, 210);
		pdf.text('CRO / MLRO', 10, 217).setFont(undefined, 'normal');

		pdf.setTextColor(71, 84, 103).text('M: +44 7971 922 345', 10, 224);
		pdf.text('E:', 10, 231).setTextColor(0, 145, 255).text('support@junomoney.com', 17, 231);
		pdf.setTextColor(71, 84, 103).text('W: https://junomoney.com', 10, 238);

		const footerLines = pdf.splitTextToSize(
			`Juno Pay Ltd (trading as Juno Money) - (Company Number: 2024801421), with its registered office at Suite #229, 6030 88ST NW, Edmonton, Alberta, T6E6G4, Canada. Juno Pay Ltd is authorised by FINTRAC as a Money Service Business (MSB No. M23335654).`,
			220
		);
		let footerTextY = 250;
		footerLines.forEach((line, index) => {
			pdf.setFontSize(12).text(line, 10, footerTextY + index * 7);
		});

		pdf.save('account-confirmation.pdf');
	};
	const JncAccountConfirmationStatus = clientData?.onboardingStatus === 'Approved';

	return (
		<div>
			<div className="min-768 jncProfile__header pt-5">
				<JncSectionTitle title="Account Confirmation Letter">
				</JncSectionTitle>
				<div className="jnAccConf min-768">
					<div className="jnAccConf__block">
						<img className="jnAccConf__icon" src={`${imageBaseUrl}/junoclient/pdf-icon.svg`} />
						<p className="jnAccConf__title">Download Account confirmation.pdf</p>

						<div className="jnAccConf__btn">
						<JncButton icon="download" text="Download" onClickCall={JncAccountConfirmationStatus ? generatePDF : null} />
						</div>
					</div>
				</div>
			</div>
			{!activeTab &&
				<button className="max-767 jncProfile__actions-btn" onClick={JncAccountConfirmationStatus ? generatePDF: null}>Download Account Confirmation</button>
			}
		</div>
	)
}