import React, { useState, useEffect } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import { useDispatch, useSelector } from 'react-redux'
import {
    getCurrencySymbol,
    validateEmail,
    clearAllValuesInObj,
    validateAmount,
    getClientTransactionUrl,
    toFixedTrunc,
    useJunoCommonFunctions,
    validateCurrency,
} from '../../../../../helpers'
import { apiService } from '../../../../../../common/apiCallService'
import { setSuccessMessage } from '../../../../../../Redux/actions/junoAction'
import JncCurrenciesTypeahead from '../../../../components/global/JncCurrenciesTypeahead'
import JncButton from '../../../../components/global/JncButton'
import TransferConfirm from '../../../../components/form/TransferConfirm'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'

export default function BalanceFiatTransfer({
    onClose,
    open,
    row,
    selectCurrency,
    selectedMerchant,
}) {
    const { getBalances, getTransactions, fetchTransactionOverview } = useJunoCommonFunctions()
    const [isLoading, setIsLoading] = useState(false)
    const [isFailed, setIsFailed] = useState(false)
    const clientData = useSelector((state) => state.juno.clientData)
    const api_url = useSelector((state) => state.config.api_url)
    const [amountError, setAmountError] = useState('')
    const [currencyError, setCurrencyError] = useState("")
    const [formData, setFormData] = useState({
        email: '',
        currency: '',
        amount: '',
        reference: '',
    })
    const [failedErr, setFailedErr] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const [isConfirm, setIsConfirm] = useState(false)
    const [successModal, setSuccessModal] = useState(false)

    const dispatch = useDispatch()
    const [selectedCurrency, setSelectedCurrency] = useState([])
    const [errorInputNames, setErrorsInputNames] = useState([])
    const balance =
        selectedCurrency && selectedCurrency[0]
            ? balancesData.find(
                (x) => x.currencyShortName === selectedCurrency[0]
            )
            : null

    useEffect(() => {
        if (row) {
            if (!row.transactionDetails) {
                setSelectedCurrency(
                    row.currencyShortName ? [row.currencyShortName] : []
                )
            } else {
                setFormData((prev) => ({
                    ...prev,
                    email: row.transactionDetails[0].email,
                    reference: row.transactionDetails[0].reference,
                }))
                setSelectedCurrency([row.currency || ''])
            }
        }
    }, [row, open])

    useEffect(() => {
        if (selectedCurrency.length > 0 && formData.amount) {
            setAmountError(
                validateAmount(
                    formData.amount,
                    null,
                    selectedCurrency,
                    balancesData,
                ),
            )
        }
    }, [selectedCurrency])

    const declineConfirm = () => {
        setIsConfirm(false)
    }

    function onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        setFormData((x) => {
            return {
                ...x,
                [name]: value,
            }
        })
        if (name == 'amount') {
            setAmountError(
                validateAmount(value, null, selectedCurrency, balancesData)
            )
            if (
                validateAmount(value, null, selectedCurrency, balancesData) ===
                ''
            ) {
                setErrorsInputNames((prev) =>
                    prev.filter((x) => x !== 'amount')
                )
                setAmountError('')
            }
        }
        if (name == 'email') {
            setEmailErr(validateEmail(value, clientData.clientEmail))
            if (validateEmail(value, clientData.clientEmail) === '') {
                setErrorsInputNames((prev) => prev.filter((x) => x !== 'email'))
            }
        }
    }

    function validateFields() {
        let updatedErrors = []
        if (
            validateEmail(formData.email, clientData.clientEmail) &&
            !selectedMerchant
        ) {
            updatedErrors = [...updatedErrors, 'email']
            setEmailErr(validateEmail(formData.email, clientData.clientEmail))
        }
        if (validateCurrency(
            selectedCurrency,
            balancesData,
        )) {
            updatedErrors = [...updatedErrors, 'currency']
            setCurrencyError(
                validateCurrency(
                    selectedCurrency,
                    balancesData,
                )
            )
        }
        if (
            validateAmount(
                formData.amount,
                null,
                selectedCurrency,
                balancesData
            )
        ) {
            updatedErrors = [...updatedErrors, 'amount']
            setAmountError(
                validateAmount(
                    formData.amount,
                    null,
                    selectedCurrency,
                    balancesData
                )
            )
        }
        setErrorsInputNames(updatedErrors)
        return updatedErrors
    }

    function submit() {
        setFormData({ ...formData, currency: selectedCurrency[0] })
        setIsLoading(true)
        const transactionEmail = localStorage.getItem('user_name')
        let payload = {
            currencyType: 'fiat',
            type: 'Transfer',
            currency: selectedCurrency[0],
            transactionDetails: {
                ...formData,
                email:
                    formData.email || selectedMerchant?.clientId?.clientEmail,
                currency: selectedCurrency[0],
            },
            client: clientData.clientId,
            transactionEmail,
            balance: {
                balanceAmount: formData.amount,
                updated: new Date(),
            },
        }
        if (selectedMerchant) {
            payload = {
                ...payload,
                transferInfee:
                    selectedMerchant && selectedMerchant.transferInFees,
            }
        }

        apiService(
            getClientTransactionUrl(api_url),
            payload,
            async (data) => {
                if (data) {
                    setIsLoading(false)
                    getBalances()
                    getTransactions()
                    fetchTransactionOverview()
                    setIsConfirm(false)
                    dispatch(
                        setSuccessMessage(
                            'Transfer has been completed successfully'
                        )
                    )
                    onCloseHandler()
                    setSuccessModal(true)
                }
            },
            (err) => {
                console.log(err)
                setIsConfirm(false)
                setIsLoading(false)
                setIsFailed(true)
                setFailedErr(err)
            }
        )
    }

    function onCloseHandler(isSuccess = false) {
        if (!isLoading) {
            setFormData(isSuccess ? clearAllValuesInObj(formData) : formData);
            onClose();
            setIsFailed(false);
            setIsConfirm(false);
            setFailedErr('');
            setAmountError('');
            setEmailErr('');
            setCurrencyError("")
        }
    }

    function reset() {
        setFormData(clearAllValuesInObj(formData))
        setIsLoading(false)
        setIsFailed(false)
        setFailedErr('')
        setAmountError('')
        setEmailErr('')
        setCurrencyError("")
    }

    const handleToCurrency = (val) => {
        setSelectedCurrency(val)
        setCurrencyError(
            validateCurrency(
                val,
                balancesData,
            ),
        )
        if (
            validateCurrency(val, balancesData) ===
            ''
        ) {
            setErrorsInputNames((prev) =>
                prev.filter((x) => x !== 'currency')
            )
        }
        setFormData({ ...formData, currency: val[0] })
    }


    function DialogContentComponent() {
        return (
            <div>
                {isConfirm && (
                    <TransferConfirm
                        balance={balance}
                        formData={formData}
                        isLoading={isLoading}
                        selectedMerchant={selectedMerchant}
                    />
                )}
                {row && !isConfirm && (
                    <div className="balance-fiat-fx">
                        {selectedMerchant && (
                            <div className="jncModal__field mb">
                                <p className="jncModal__label">
                                    Sending to{' '}
                                    <span className="required">*</span>
                                </p>
                                <div className="jncInput flex items-center gap-2">
                                    <img
                                        src={selectedMerchant.merchantLogo}
                                        alt="merchantLogo"
                                        width={32}
                                        height={32}
                                        className="rounded-full"
                                    />
                                    <h1 className="">
                                        {selectedMerchant.merchantName}
                                    </h1>
                                </div>
                            </div>
                        )}

                        <JncCurrenciesTypeahead
                            selected={selectedCurrency}
                            onChange={handleToCurrency}
                            hasError={!!currencyError}
                            error={currencyError}
                        />

                        {!selectedMerchant && (
                            <div className="jncModal__field mb">
                                <p className="jncModal__label">
                                    Account Email{' '}
                                    <span className="required">*</span>
                                </p>
                                <input
                                    data-e2e="account-email"
                                    className={`jncInput ${emailErr ? 'has-error' : ''
                                        }`}
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => onChangeInput(e)}
                                    type="text"
                                    placeholder="Enter Account Email"
                                />
                                {emailErr && (
                                    <div
                                        className="jncAlert alert alert-danger"
                                        role="alert"
                                    >
                                        {emailErr}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="jncModal__field mb">
                            <p className="jncModal__label">
                                Amount <span className="required">*</span>{' '}
                                <br />
                                {balance && (
                                    <span>
                                        (Available:{' '}
                                        {getCurrencySymbol(
                                            balance.currencyShortName
                                        )}{' '}
                                        {toFixedTrunc(balance.balanceAmount, 2)}
                                        )
                                    </span>
                                )}
                            </p>
                            <div className="jncModal_amount_input">
                                <div
                                    className={`jn-client-prefix-input ${amountError ? 'has-error' : ''
                                        }`}
                                >
                                    <span>
                                        {balance
                                            ? getCurrencySymbol(
                                                balance.currencyShortName
                                            )
                                            : '?'}
                                    </span>
                                    <input
                                        type="text"
                                        data-e2e="amount"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={(e) => onChangeInput(e)}
                                        placeholder="Enter Amount"
                                    />
                                </div>
                                <div className="theme-modal-field-input readOnly">
                                    {(balance && balance.currencyShortName) ||
                                        '?'}
                                </div>
                            </div>
                            {amountError && (
                                <div
                                    className="jncAlert alert alert-danger"
                                    role="alert"
                                >
                                    {amountError}
                                </div>
                            )}
                        </div>
                        <div className="jncModal__field ">
                            <p className="jncModal__label">Reference</p>
                            <input
                                data-e2e="reference-textbox"
                                onChange={(e) => onChangeInput(e)}
                                value={formData.reference}
                                name="reference"
                                className="jncInput"
                                type="text"
                                placeholder="Enter Reference (optional)"
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="w-100">
                {isConfirm && (
                    <div className="jncModal__btns grid-on-mobile justify-end no-border">
                        <JncButton
                            disabled={isLoading}
                            text="Cancel"
                            isOutlinedStyle={true}
                            onClickCall={declineConfirm}
                        />
                        <JncButton
                            text="Confirm"
                            loading={isLoading}
                            onClickCall={submit}
                            dataE2e="confirm-btn"
                        />
                    </div>
                )}
                {!isConfirm && (
                    <div className="jncModal__btns justify-end">
                        <JncButton
                            isFullOnMobile={true}
                            loading={isLoading}
                            disabled={amountError || currencyError || emailErr}
                            onClickCall={setIsConfirmHandler}
                            dataE2e="transfer-btn"
                            text="Transfer"
                        />
                    </div>
                )}
            </div>
        )
    }

    const setIsConfirmHandler = () => {
        const error = validateFields()
        if (error.length === 0) {
            setIsConfirm(true)
        }
    }

    useEffect(() => {
        if (open) {
            fetchBalances()
        }
    }, [open])


    const fetchBalances = async () => {
        await getBalances()
    }

    const handleSuccessModal = () => {
        setSuccessModal(false)
        setFormData(clearAllValuesInObj(formData))
    };

    return (
        <>
            <JunoClientActionModal
                onClose={() => onCloseHandler(true)}
                titles={{ start: 'Transfer', confirm: 'Transfer Confirmation' }}
                backTo={{ account: declineConfirm }}
                states={{
                    start: !isConfirm,
                    confirm: isConfirm,
                }}
                open={open}
                isLoading={isLoading}
                isFailed={isFailed}
                failedErr={failedErr}
                reset={reset}
                dialogContent={DialogContentComponent()}
                dialogActions={DialogActionsComponent()}
                actionDivider={false}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData.amount}
                currency={selectedCurrency?.[0] || formData.currency}
                title="Successful!"
                subtitle="Has been transfered successfully!"
            />
        </>
    );
}
