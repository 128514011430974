import React, { useEffect, useState } from 'react'
import RegistrationLayout from '../../client/layouts/RegistrationLayout'
import { useSelector, useDispatch } from 'react-redux'
import JunoVerifyEmail from '../components/register/JunoVerifyEmail'
import JunoCreatePassword from '../components/register/JunoCreatePassword'
import JunoRegistrationBusinessDetails from '../components/register/JunoRegistrationBusinessDetails'
import BusinesssDocumentation from '../components/register/BusinesssDocumentation'
import TransactionActivity from '../components/register/TransactionActivity'
import JunoAccountPurpose from '../components/register/JunoAccountPurpose'
import Stepper from '../components/register/Stepper'
import { apiService } from '../../../common/apiCallService'
import { useNavigate, useLocation } from 'react-router-dom'

const stepMap = {
    onboarding: 1,
    emailVerification: 2,
    passwordCreation: 3,
    uploadDocuments: 4,
    accountPurpose: 5,
    transactionActivity: 6,
    kycVerification: 7,
    finalVerification: 8
}

export default function BusinessRegistrationPage() {
    const [headerContent, setHeaderContent] = useState({
        title: '',
        isBack: false,
        isSave: false,
    })
    const apiUrl = useSelector((state) => state.config.api_url)
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [form, setForm] = useState({
        businessEmail: '',
        fullLegalEntityName: '',
        businessRepresentativeName: '',
        businessWebsite: '',
        countryOfIncorporation: '',
        address: '',
        city: '',
        state: '',
        postCode: ''

    })
    const dispatch = useDispatch()
    const [registrationData, setRegistrationData] = useState(null)
    const navigate = useNavigate()
    const location = useLocation();
    const navigateState = location.state;
    const [errorMsg, setErrorMsg] = useState()
    const [businessData, setBusinessData] = useState(null)

    useEffect(() => {
        navigateState && setStep(stepMap[navigateState?.step])
    }, [navigateState])


    const handleCreateAccount = () => {
        setLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/register',
            {
                accountType: 'Business',
                ...form,
            },
            (data) => {
                setLoading(false)
                const next = data?.userRegistrationState?.emailVerification
                    ? 3
                    : 2
                setStep(next)
            },
            (err) => {
                setLoading(false)
                setErrorMsg(err)
                console.log(err)
            }
        )
    }

    useEffect(() => {
        if (step >= 4) {
            getClientBusinessData()
        }
    }, [step])


    const getClientBusinessData = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/get-client-account-details',
            {
                clientEmail: form.businessEmail || localStorage.getItem('user_name')
            },
            (data) => {
                setBusinessData(data)
            },
            (err) => {
                console.error(err)
            }
        )
    }
    const updateClientData = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/update-single-client-data',
            {
                clientEmail: form.businessEmail || localStorage.getItem('user_name'),
                onboardingStatus: "AwaitingApproval",

            },
            (data) => {

            },
            (err) => {
                console.error(err)
            }
        )
    }

    useEffect(() => {
        switch (step) {
            case 1:
                setHeaderContent({
                    title: 'Business details',
                    isBack: true,
                    isSave: false,
                })
                break
            case 2:
                setHeaderContent({
                    title: 'Verify Business Email',
                    isBack: true,
                    isSave: false,
                })
                break
            case 3:
                setHeaderContent({
                    title: 'Create a Password',
                    isBack: true,
                    isSave: false,
                })
                break
            case 4:
                setHeaderContent({
                    title: 'Business Documentation',
                    isBack: false,
                    isSave: true,
                })
                break
            case 5:
                setHeaderContent({
                    title: 'Account Purpose',
                    isBack: true,
                    isSave: true,
                })
                break
            case 6:
                setHeaderContent({
                    title: 'Transaction Activity',
                    isBack: true,
                    isSave: true,
                })
                break
        }
    }, [step])

    const handleBack = () => {
        step === 1 ? navigate('/invite-to-register') : setStep(step - 1)
    }
    const steps = ['Details', 'Verify', 'Password', 'Docs', 'Purpose', 'Transactions']

    const handleSaveClick = async () => {
        navigate('/client');
    };


    return (
        <RegistrationLayout
            headerContent={headerContent}
            handleBack={handleBack}
            handleSaveClick={handleSaveClick}
            stepper={
                <Stepper steps={steps} currentStep={step} />
            }
        >
            {step === 1 && (
                <JunoRegistrationBusinessDetails
                    form={form}
                    setForm={setForm}
                    errorMsg={errorMsg}
                    loading={loading}
                    handleCreateAccount={handleCreateAccount}
                />
            )}
            {step === 2 && (
                <JunoVerifyEmail
                    title="Verify Business Email"
                    onClick={() => setStep(3)}
                    onBack={() => setStep(1)}
                    email={form.businessEmail}
                    setRegistrationData={setRegistrationData}
                />
            )}

            {step === 3 && (
                <JunoCreatePassword
                    onBack={() => registrationData?.userRegistrationState
                        ?.emailVerification
                        ? setStep(1)
                        : setStep(2)}
                    setRegistrationData={setRegistrationData}
                    form={form}
                    onClick={() => {
                        setStep(4)
                    }}
                />
            )}

            {step === 4 && (
                <BusinesssDocumentation
                    handleStep={() => {
                        setStep(5)
                    }}
                    handleSaveClick={handleSaveClick}
                    businessData={businessData}
                    getClientBusinessData={getClientBusinessData}
                    form={form}
                />
            )}

            {step === 5 && (
                <JunoAccountPurpose
                    onBack={() => setStep(4)}
                    onClick={() => {
                        setStep(6)
                    }}
                    handleSaveClick={handleSaveClick}
                />
            )}
            {step === 6 && (
                <TransactionActivity
                    accountType="Business"
                    onBack={() => setStep(5)}
                    onClick={() => { handleSaveClick(); updateClientData() }}
                    handleSaveClick={handleSaveClick}
                />
            )}
        </RegistrationLayout>
    )
}
