export function handleFormErrors(errors) {
    if (!errors || Object.keys(errors).length === 0) return false;

    const hasErrors = Object.values(errors).some((err) => err !== '');
    if (!hasErrors) return false;

    const firstErrorField = Object.keys(errors).find((key) => errors[key] !== '');
    if (!firstErrorField) return false;

    const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
    if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => errorElement.focus(), 300);
    }

    return true;
}
