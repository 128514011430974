
export const SET_LOADING = 'SET_LOADING';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_IMAGE_BASE_URL = 'SET_IMAGE_BASE_URL';
export const SET_JUNO_TABLE_SEARCH_VALUE = 'SET_JUNO_TABLE_SEARCH_VALUE';
export const SET_INDIVIDUAL_STATE_INFO = 'SET_INDIVIDUAL_STATE_INFO';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const SET_BALANCES_DATA = 'SET_BALANCES_DATA';
export const SET_ACCOUNTS_DATA = 'SET_ACCOUNTS_DATA';
export const SET_TRANSACTIONS_DATA = 'SET_TRANSACTIONS_DATA';
export const SET_STATEMENTS_DATA = 'SET_STATEMENTS_DATA';
export const SET_CLIENT_FEE_SETTING = 'SET_CLIENT_FEE_SETTING';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SET_CURRENCY_PAGE = 'SET_CURRENCY_PAGE';
export const SET_CRYPTO_BALANCES_DATA = 'SET_CRYPTO_BALANCES_DATA';
export const SET_IS_BUSINESS_VERIFIED = 'SET_IS_BUSINESS_VERIFIED'
export const SET_SHOW_VERIFICATION_MODAL = 'SET_SHOW_VERIFICATION_MODAL';
export const SET_MANAGED_CURRENCIES = 'SET_MANAGED_CURRENCIES';
export const SET_SELECTED_TRANASCTION_OVERVIEW = 'SET_SELECTED_TRANASCTION_OVERVIEW';
export const SET_TRANASCTION_OVERVIEW_DATA = 'SET_TRANASCTION_OVERVIEW_DATA';
