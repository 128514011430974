import React from 'react'
import JncAuthBottom from '../../JunoMoney/components/global/auth/JncAuthBottom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useJunoCommonFunctions } from '../../helpers'

const RegistrationLayout = ({ children, headerContent, handleBack, stepper = null, handleSaveClick }) => {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const navigate = useNavigate()
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    return (
        <div className="w-screen h-screen flex flex-col bg-brand-black">
            <div className="px-4 lg:!px-[150px] py-6 lg:py-0 lg:h-[100px] flex items-center flex-wrap md:flex-nowrap justify-between border-b border-[#51525C]">
                <img
                    onClick={() => navigate('/')}
                    className="h-6 cursor-pointer hidden lg:block"
                    src={`${imageBaseUrl}/landingpage/light-logo.svg`}
                    alt="logo"
                />
                {headerContent?.isBack && (
                    <button onClick={handleBack} className="lg:hidden">
                        <ArrowBackIcon className="text-[#A0A0AB] mr-[10px]" />
                    </button>
                )}
                <p className="lg:hidden text-lg font-semibold text-white flex-1">
                    {headerContent?.title || 'Create Your Account'}
                </p>
                {stepper && <div className='lg:flex-1 w-full mt-6 lg:mt-0  order-9 lg:order-2'>
                    {stepper}
                </div>}
                {headerContent?.isSave && windowWidth < 768 ? (
                    <div className="h-7 px-3.5 py-[5.25px] bg-white/20 rounded-full justify-center items-center gap-[8.75px] inline-flex md:hidden">
                        <div
                            className="text-white text-xs font-medium"
                            onClick={() => handleSaveClick()}
                        >
                            Skip
                        </div>
                    </div>
                ) : (
                    <button
                        className="order-3"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <ClearIcon
                            style={{ color: 'white' }}
                            className="icon-close"
                        />
                    </button>
                )}
            </div>

            {/* Content */}
            <div className="flex-1 flex justify-center items-center bg-white md:!bg-brand-black">
                {children}
            </div>

            <div className="hidden md:block px-4">
                <JncAuthBottom />
            </div>
        </div>
    )
}

export default RegistrationLayout
