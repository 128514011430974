import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import JncButton from '../../components/global/JncButton'
import { JncCustomSelect } from '../../components/global/JncCustomSelect'
import { useDispatch, useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import CopyButton from '../../components/global/CopyButton'
import { JncConfirm } from '../../components/global/JncConfirm'
import { setErrorMessage, setSuccessMessage } from '../../../../Redux/actions/junoAction'
import JunoClientSnackbar from '../../components/global/JunoClientSnackbar'
import Loading from '../../../../common/Loading'
import { isBlank } from '../../../../../helpers/utils'
import { filterBalances } from '../../../helpers'
import { MenuItem, Select } from '@mui/material'

const initialFormData = {
    cryptoId: 'BTC',
    nickName: '',
    address: '',
    network: '',
};

export const JncCryptoAddresses = (props) => {
    const { onClose, open, title, getCryptoAddress, addressData, addressActions } = props;
    const { balancesCrypto } = useSelector((state) => state.juno)
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const [formData, setFormData] = useState(initialFormData)
    const [isConfirm, setIsConfirm] = useState(false)
    const [walletError, setWalletError] = useState(null)

    useEffect(() => {
        if (addressData && addressActions?.edit) {
            setFormData({
                cryptoId: addressData?.cryptoId || 'BTC',
                nickName: addressData?.nickName || '',
                address: addressData?.address || '',
                network: addressData?.blockchain || ''
            })
        }
    }, [addressData, addressActions])


    const handleAddress = (endpoint, payload) => {
        setIsLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') + endpoint,
            payload,
            async (data) => {
                if (data) {
                    setIsLoading(false)
                    handleModalClose()
                    getCryptoAddress();
                    dispatch(setSuccessMessage(`Address ${endpoint.includes("save") ? 'added' : "updated"} successfully`))
                }
            },
            (err) => {
                setIsLoading(false)
                console.log(err);
                dispatch(setErrorMessage(err))
            }
        );
    };

    const validateWalletAddress = () => {

        setIsLoading(true);

        const selectedCrypto = balancesCrypto.find(crypto => crypto?.currencyShortName === formData.cryptoId);

        const payload =  {
            crypto: formData.cryptoId,
            blockchain: formData?.network || selectedCrypto?.blockchain,
            walletAddress: formData.address,
            ...(selectedCrypto?.walletNetwork && { network: selectedCrypto.walletNetwork }),
        }

        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') + '/restapi/validate-wallet-address',
            payload,
            async (response) => {
                if (response?.error) {
                    setWalletError(response?.message?.message || 'Validations failed')
                    return
                } 

                if (addressActions?.edit) {
                    setIsConfirm(true)
                    return
                } else {
                    saveNewAddress()
                }
            },
            (err) => {
                console.log(err);
                dispatch(setErrorMessage(err || 'Validation Error'));
            }
        );
    };

    const saveNewAddress = () => {
        const blockchain = balancesCrypto?.find(
            crypto => crypto?.currencyShortName === formData.cryptoId,
        )?.blockchain;

        handleAddress('/restapi/save-crypto-address', {
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            blockchain: formData.network || blockchain,
        });
    };

    const updateAddress = () => {
        const blockchain = balancesCrypto?.find(
            crypto => crypto?.currencyShortName === formData.cryptoId,
        )?.blockchain;

        handleAddress('/restapi/update-crypto-address', {
            clientId: clientData.clientId,
            cryptoId: formData.cryptoId,
            address: formData.address,
            nickName: formData.nickName,
            addressId: addressData._id,
            blockchain: formData.network || blockchain,
        });
    };

    const handleModalClose = () => {
        setFormData(initialFormData);
        setWalletError(null)
        onClose()
    }

    const dialogContentComponent = () => {
        return (
            <>
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Select crypto:
                    </h1>
                    <div className="col-span-2">
                        <JncCustomSelect
                            value={formData.cryptoId}
                            onChange={(e) =>
                                setFormData({ ...formData, cryptoId: e })
                            }
                            options={filterBalances(balancesCrypto)}
                            className="!w-full !rounded-l-3xl"
                            inputProps={{
                                className: '!py-[15px]',
                            }}
                        />
                    </div>
                </div>
                {formData.cryptoId === 'USDT' && (
                    <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                        <h1 className="text-base font-normal text-[#26272B] col-span-1">
                            Network:
                        </h1>
                        <div className="col-span-2">
                            <Select
                                value={formData?.network || 'placeholder'}
                                className="bg-input max-sm:col-span-3 col-span-2 max-sm:mt-2 !rounded-3xl w-full"
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        network: e.target.value,
                                    })
                                }
                                displayEmpty
                                renderValue={(selected) =>
                                    selected === 'placeholder'
                                        ? 'Select network'
                                        : selected
                                }
                            >
                                <MenuItem key="Ethereum" value="Ethereum">
                                    Ethereum
                                </MenuItem>
                                <MenuItem key="Tron" value="Tron">
                                    Tron
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                )}
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Wallet Address:
                    </h1>
                    <div
                        className={`jn-addressdetails-content-input col-span-2 bg-input rounded-3xl py-[17px] pl-6 overflow-hidden ${
                            walletError
                                ? 'border-[#FDA29B] border-2'
                                : 'border-[#D1D1D6] border'
                        }`}
                    >
                        <input
                            type="text"
                            placeholder="Enter wallet address"
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    address: e.target.value,
                                })
                            }
                            value={formData.address}
                            className="bg-input w-10/12 outline-none"
                        />
                        {addressActions?.edit && (
                            <CopyButton
                                className="jn-addressdetails-content-copy"
                                copyValue={formData.address}
                            />
                        )}
                    </div>
                </div>
                {walletError && (
                    <div className="jncAlert alert alert-danger" role="alert">
                        {walletError}
                    </div>
                )}
                <div className="grid items-center grid-cols-3 max-md:grid-cols-subgrid gap-3 md:gap-8 mt-8">
                    <h1 className="text-base font-normal text-[#26272B] col-span-1">
                        Nickname:
                    </h1>
                    <input
                        type="text"
                        placeholder="Create address nickname"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                nickName: e.target.value,
                            })
                        }
                        value={formData.nickName}
                        className="bg-input col-span-2 rounded-3xl w-full py-[17px] pl-6"
                    />
                </div>
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {(!isBlank(formData?.address) && !isBlank(formData?.nickName) && addressActions?.edit) &&
                    <div className="jncModal__actions addressTwoBtns-right justify-end ">
                        <JncButton
                            isFullOnMobile={true}
                            text="Save address"
                            onClickCall={() => {
                                validateWalletAddress()
                            }}
                        />
                    </div>
                }

                {(!isBlank(formData?.address) && !isBlank(formData?.nickName) && !(addressActions?.edit)) &&
                    <div className="jncModal__actions addressTwoBtns-right justify-end ">
                        <JncButton
                            isFullOnMobile={true}
                            text="Create new"
                            onClickCall={validateWalletAddress}
                            loading={isLoading}
                        />
                    </div>}

            </div>
        )
    }

    return (
        <>
            <JunoClientActionModal
                onClose={handleModalClose}
                titles={{ start: title, className: "jn-addressdetails-contentweight" }}
                dialogClassName={"jn-addressdetails-view"}
                contentClassName="jn-addressdetails-contentviewbox"
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
            <JncConfirm
                open={isConfirm}
                data={formData.address}
                onClose={() => setIsConfirm(false)}
                title="Are you sure?"
                content="Are you sure you want to change the wallet address?"
                dialogClassName={"jn-addressdetails-confirm"}
                actionClassName={"jn-addressdetails-actionbox"}
                handleConfirm={updateAddress}
            />
            <JunoClientSnackbar />
        </>
    )
}
