import * as actionTypes from '../actions/actionTypes'

const initialState = {
    searchValue: '',
    isIndividualState: true,
    clientData: null,
    balancesCurrencies: [],
    balancesCrypto: [],
    transactionsCurrencies: [],
    transactionsCrypto: [],
    clientTransactionFee: [],
    totalFiatAmount: 0,
    totalCryptoAmount: 0,
    savedAccounts: [],
    statements: [],
    activeCurrencyPage: '',
    snackbar: { open: false, message: '', severity: '' },
    allTransactions: [],
    unfreezeFiatCurrencies: [],
    isBusinessVerfied: false,
    managedCurrencies: ['USD', 'EUR', 'GBP', 'BTC', 'ETH', 'LTC'],
    selectedTranasctionOverview: { key: 'today', label: 'Today', treadText: 'Today' },
    overviewData: [
        { amount: '0', key: 'total', label: 'Total', iconType: 'Restore', bgIcon: 'bg-[#D1E9FF]', position: 0 },
        { amount: '0', key: 'success', label: 'Completed', iconType: 'Check', bgIcon: 'bg-[#ECFDF3]', position: 1 },
        { amount: '0', key: 'processing', label: 'Processing', iconType: 'AccessTime', bgIcon: 'bg-[#F4F4F5]', position: 2 },
        { amount: '0', key: 'failed', label: 'Failed', iconType: 'Clear', bgIcon: 'bg-[#FEF3F2]', position: 3 },
    ],
}

function setJunoTableSearchValue(state, action) {
    return {
        ...state,
        searchValue: action.value,
    }
}

function setIndividualStateInfo(state, action) {
    return {
        ...state,
        isIndividualState: action.value,
    }
}

function setClientData(state, action) {
    return {
        ...state,
        clientData: action.value,
    }
}

function setBalancesData(state, action) {
    return {
        ...state,
        balancesCurrencies: action.value.clientBalanceList,
        unfreezeFiatCurrencies: action.value.clientBalanceList
            ? action.value.clientBalanceList.filter(
                (balance) => !balance.isFreeze
            )
            : [],
        totalFiatAmount: action.value.totalFiatAmount,
    }
}

function setCryptoBalancesData(state, action) {
    return {
        ...state,
        balancesCrypto: action.value.clientBalanceList,
        unfreezeCryptoCurrencies: action.value.clientBalanceList
            ? action.value.clientBalanceList.filter(
                (balance) => balance.isFreeze === false
            )
            : [],
        totalCryptoAmount: action.value.totalCryptoAmount,
    }
}

function setAccountsData(state, action) {
    return {
        ...state,
        savedAccounts: action.value,
    }
}

function setTransactionsData(state, action) {
    return {
        ...state,
        transactionsCurrencies: action.value.filter(
            (x) => x.currencyType === 'fiat'
        ),
        transactionsCrypto: action.value.filter(
            (x) => x.currencyType === 'crypto'
        ),
        allTransactions: action.value,
        allTransactionsFiatCurrencies: state.transactionsCrypto,
    }
}

function setStatementsData(state, action) {
    return {
        ...state,
        statements: action.value,
    }
}

function setClientTransferFee(state, action) {
    return {
        ...state,
        clientTransactionFee: action.value,
    }
}

function setJunoSnackbarState(state, action) {
    return {
        ...state,
        snackbar: {
            open: true,
            message: action.payload.message,
            severity: action.payload.severity,
        },
    }
}

function closeSnackbar(state, action) {
    return {
        ...state,
        snackbar: {
            open: false,
        },
    }
}

function setActiveCurrencyPage(state, action) {
    const { value } = action
    if (value != null) {
        return { ...state, activeCurrencyPage: value }
    }
    return state
}

const setIsBusinessVerified = (state, action) => {
    return {
        ...state,
        isBusinessVerfied: action.data,
    };
};
function setManagedCurrencies(state, action) {
    return {
        ...state,
        managedCurrencies: action.value,
    }
}

const setSelectedTranasctionOverview = (state, action) => {
    return {
        ...state,
        selectedTranasctionOverview: action.value,
    }
}

const setOverviewData = (state, action) => {
    return {
        ...state,
        overviewData: state.overviewData.map((item) => {
            const _mt = action.value.find((x) => x.actualStatus === item.key);
            return {
                ...item,
                amount: _mt?.transactionCount?.toString() || '0',
                tradingInfo: `${_mt?.trend || '-0.00%'}  ${state.selectedTranasctionOverview.treadText}`,
                tradingIcon:
                    _mt?.current >= _mt?.previous
                        ? `/common/upward_green_icon.svg`
                        : `/common/downward_red_icon.svg`,
            };
        }).sort((a, b) => a.position - b.position)
    }
}

function junoReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_JUNO_TABLE_SEARCH_VALUE:
            return setJunoTableSearchValue(state, action)
        case actionTypes.SET_INDIVIDUAL_STATE_INFO:
            return setIndividualStateInfo(state, action)
        case actionTypes.SET_CLIENT_DATA:
            return setClientData(state, action)
        case actionTypes.SET_BALANCES_DATA:
            return setBalancesData(state, action)
        case actionTypes.SET_TRANSACTIONS_DATA:
            return setTransactionsData(state, action)
        case actionTypes.SET_CLIENT_FEE_SETTING:
            return setClientTransferFee(state, action)
        case actionTypes.SET_SUCCESS_MESSAGE:
        case actionTypes.SET_ERROR_MESSAGE:
            return setJunoSnackbarState(state, action)
        case actionTypes.CLOSE_SNACKBAR:
            return closeSnackbar(state, action)
        case actionTypes.SET_ACCOUNTS_DATA:
            return setAccountsData(state, action)
        case actionTypes.SET_STATEMENTS_DATA:
            return setStatementsData(state, action)
        case actionTypes.SET_CURRENCY_PAGE:
            return setActiveCurrencyPage(state, action)
        case actionTypes.SET_CRYPTO_BALANCES_DATA:
            return setCryptoBalancesData(state, action)
        case actionTypes.SET_IS_BUSINESS_VERIFIED:
            return setIsBusinessVerified(state, action)
        case actionTypes.SET_MANAGED_CURRENCIES:
            return setManagedCurrencies(state, action)
        case actionTypes.SET_SELECTED_TRANASCTION_OVERVIEW:
            return setSelectedTranasctionOverview(state, action)
        case actionTypes.SET_TRANASCTION_OVERVIEW_DATA:
            return setOverviewData(state, action)
        default:
            return state
    }
}

export default junoReducer
