import React from 'react'
import FadeUp from './FadeUp'

const ServicesComponent = () => {
    const services = [
        { id: 1, title: "Instant transfers", description: "Send funds to other Juno Money users instantly." },
        { id: 2, title: "Exchange in 30+ currencies", description: "Exchange in multiple currencies including GBP, EUR, USD, CAD, TRY, CHF, AED and many more." },
        { id: 3, title: "Quick onboarding process", description: "Onboarding for a corporate account is quick and seamless." },
        { id: 4, title: "Dedicated account manager", description: "Dedicated account manager to support you with your Juno Money journey." },
        { id: 5, title: "Withdraw with ease", description: "Withdraw your funds utilising our global banking networks." },
        { id: 6, title: "Accept payments", description: "Accept payments into your account from around the world." },
    ]
    return (
        <div className='bg-black'>
            <div className='lg:px-[150px] lg:pt-[60px] md:pb-16 lg:pb-28 xl:px-0 gap-10 max-w-[1280px] mx-auto'>
                {services.map((service, index) =>
                    <FadeUp delay={80}>
                        <div id={service.id} className={`flex flex-wrap px-6 lg:px-0 py-10 border-b border-brand-gray ${index === 0 ? "border-t" : ""}`}>
                            <span className='w-12 text-xl text-off-white'>
                                0{service.id}
                            </span>
                            <span className='w-[266px] lg:w-[500px] text-xl text-off-white'>
                                {service.title}
                            </span>
                            <span className='flex-1 min-w-[300px] !mt-10 min-[662px]:!mt-0 text-xl text-[#A0A0AB]'>{service.description}</span>
                        </div>
                    </FadeUp>
                )}
            </div>
        </div>
    )
}

export default ServicesComponent