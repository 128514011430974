import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import SumsubWebSdk from '@sumsub/websdk-react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiService } from '../../../../common/apiCallService';
import CloseIcon from '@mui/icons-material/Close';
import { setKycStatus, useJunoCommonFunctions } from '../../../helpers';
import { KYCSTATUSES } from '../../constant';




const SumsubVerification = ({ start, onClose, newID }) => {
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()
    const clientData = useSelector((state) => state.juno.clientData);
    const apiUrl = useSelector((state) => state.config.api_url);
    const [loading, setLoading] = useState(false)
    const [accessToken, setAccessToken] = useState("");
    const dispatch = useDispatch()

    const fetchAccessToken = async () => {
        setLoading(true)
        const endpoint = `${window.location.hostname === 'localhost' ? apiUrl : ''}/restapi/kyc-getAccessToken`;
        apiService(
            endpoint,
            { clientEmail: clientData.clientEmail, newID },
            handleTokenSuccess,
            handleTokenError
        );
    };

    const handleTokenSuccess = (data) => {
        setLoading(false)
        if (data) {
            setAccessToken(data.token);
            return data.token
        }
    };

    const handleTokenError = (error) => {
        setLoading(false)
        console.error('Failed to get access token:', error);
    };

    const updateAccessToken = async () => {
        const newToken = await fetchAccessToken();
        return newToken;
    };

    useEffect(() => {
        fetchAccessToken();
    }, []);

    const updateClientData = (status) => {
        const body = {
            clientEmail: clientData?.clientEmail || localStorage.getItem('user_name'),
            ...(status === KYCSTATUSES.completed && {
                onboardingStatus: "AwaitingApproval",
                'userRegistrationState.kycVerification': true,
                "kyc.status": KYCSTATUSES.completed,
            }),
            ...(status === KYCSTATUSES.failed && {
                "kyc.status": KYCSTATUSES.failed,
            }),
        };
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/update-single-client-data',
            body,
            (data) => {
            },
            (err) => {
                console.error(err)
            }
        )
    }

    return (
        <div>
            <Dialog
                fullScreen={windowWidth < 768}
                className="jncModal"
                onClose={onClose}
                open={start}
            >
                <DialogContent className=' md:max-h-[600px]' dividers>
                    <div className='flex flex-col '>
                        <div className='h-9 bg-[#181818] text-white font-semibold pl-4 pr-3 py-2 flex justify-between'>
                            Verify ID
                            <CloseIcon onClick={onClose} className='cursor-pointer !w-5 !h-5 text-white m-0' />
                        </div>
                        <div className='flex justify-center flex-1 overflow-scroll !min-h-[210px] md:max-h-[550px]'>
                            {loading ?
                                <div className='h-[210px] flex items-center'>
                                    <CircularProgress />
                                </div> :
                                <SumsubWebSdk style={{ width: "100%" }}
                                    accessToken={accessToken}
                                    updateAccessToken={updateAccessToken}
                                    expirationHandler={updateAccessToken}
                                    config={{
                                        lang: 'en',
                                        i18n: { documentTitle: 'Verification Process' },
                                    }}
                                    options={{
                                        isShowCloseButton: true,
                                        isShowFullScreenButton: false
                                    }}
                                    onMessage={(type, payload) => {
                                        if (type === "idCheck.onApplicantStatusChanged") {
                                            const { reviewStatus, reviewResult } = payload
                                            if (reviewStatus === "completed") {

                                                if (reviewResult?.reviewAnswer === "GREEN") {
                                                    setKycStatus(KYCSTATUSES.completed)
                                                    updateClientData(KYCSTATUSES.completed)
                                                    setTimeout(() => {
                                                        onClose()
                                                    }, 2000);

                                                } else if (reviewResult?.reviewAnswer === "RED" && reviewResult?.reviewRejectType === "RETRY") {
                                                    setKycStatus(KYCSTATUSES.retry)
                                                } else {
                                                    if (reviewResult?.reviewRejectType === "FINAL") {
                                                        setKycStatus(KYCSTATUSES.failed)
                                                        updateClientData(KYCSTATUSES.failed)

                                                    }

                                                }
                                            }
                                        }
                                    }}
                                    onError={(error) => console.log("Error:", error)}

                                />}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SumsubVerification;
