import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    CalendarTodayOutlined,
    KeyboardArrowDownOutlined,
    Restore,
    AccessTime,
    Check,
    Clear,
} from '@mui/icons-material'
import { useJunoCommonFunctions } from '../../../helpers'
import * as actions from '../../../../Redux/actions'

const DATE_OPTIONS = [
    { key: 'today', label: 'Today', treadText: 'Today' },
    { key: 'lastWeek', label: 'Last Week', treadText: 'this week' },
    { key: 'lastMonth', label: 'Last Month', treadText: 'this month' },
    { key: 'lastQuarter', label: 'Last Quarter', treadText: 'this quarter' },
    { key: 'lastYear', label: 'Last Year', treadText: 'this year' },
]

const ICONS = {
    Restore: <Restore fontSize="inherit" />,
    AccessTime: <AccessTime fontSize="inherit" />,
    Check: <Check fontSize="inherit" />,
    Clear: <Clear fontSize="inherit" />,
}

const SkeletonCard = React.memo(({ isLast }) => (
    <div className="min-w-[280px] sm:min-w-0 sm:w-1/2 lg:w-1/4 p-2">
        <div className="flex py-3 px-4 bg-[#FAFAFA] h-full">
            <div className="w-full">
                <div className="flex justify-between w-full">
                    <div>
                        <div className="h-8 w-24 bg-gray-200 rounded animate-pulse"></div>
                        <div className="h-4 w-32 bg-gray-200 rounded mt-2 animate-pulse"></div>
                    </div>
                    <div className="h-10 w-10 bg-gray-200 rounded-lg animate-pulse"></div>
                </div>
                <div className="flex items-center mt-4">
                    <div className="h-4 w-4 bg-gray-200 rounded mr-2 animate-pulse"></div>
                    <div className="h-4 w-28 bg-gray-200 rounded animate-pulse"></div>
                </div>
            </div>
        </div>
    </div>
))

const DatePicker = React.memo(({ isOpen, toggle, selectedKey, onChange }) => {
    const handleOptionClick = (option) => {
        onChange(option)
        toggle(false)
    }

    return (
        <div
            className="relative flex items-center border-2 rounded-lg cursor-pointer w-full md:w-auto"
            onClick={() => toggle(!isOpen)}
        >
            <div className="flex p-2 justify-between w-full min-w-[180px]">
                <CalendarTodayOutlined className="text-gray-600" />
                <button className="text-sm font-semibold bg-transparent">
                    {DATE_OPTIONS.find((opt) => opt.key === selectedKey)?.label}
                </button>
                <KeyboardArrowDownOutlined className="text-gray-700" />
            </div>
            {isOpen && (
                <div className="absolute top-full mt-2 bg-white border rounded-lg shadow-lg z-10 w-full">
                    <ul className="text-sm text-gray-700">
                        {DATE_OPTIONS.map(({ key, label, treadText }) => (
                            <li
                                key={key}
                                className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleOptionClick({ key, label, treadText })
                                }}
                            >
                                {label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
})

const OverviewCard = React.memo(
    ({ data, imageBaseUrl, treadText }) => (
        <div className="min-w-[280px] sm:min-w-0 sm:w-1/2 lg:w-1/4 p-2">
            <div className="flex py-3 px-4 bg-[#FAFAFA] h-full">
                <div className="w-full">
                    <div className="flex justify-between w-full">
                        <div>
                            <h2 className="text-2xl font-bold">{data.amount}</h2>
                            <p className="text-sm text-gray-700">{data.label}</p>
                        </div>
                        <div
                            className={`w-10 h-10 sm:w-10 sm:h-10 flex items-center justify-center ${data.bgIcon} rounded-lg`}
                        >
                            {ICONS[data.iconType]}
                        </div>
                    </div>
                    <div className="flex items-center mt-2">
                        <img
                            src={`${imageBaseUrl}${
                                data.tradingIcon || '/common/downward_red_icon.svg'
                            }`}
                            alt="trend-icon"
                            className="h-4 w-4 mr-2"
                        />
                        <p className="text-sm text-gray-500">
                            {data.tradingInfo || `-0.00% this ${treadText}`}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
)

function TransactionOverview() {
    const dispatch = useDispatch()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const selectedDateOption = useSelector(
        (state) => state.juno.selectedTranasctionOverview
    )
    const overviewData = useSelector((state) => state.juno.overviewData)
    const { fetchTransactionOverview } = useJunoCommonFunctions()

    const [isDatepickerOpen, setIsDatepickerOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const handleDateChange = React.useCallback(
        (selectedOption) => {
            dispatch(actions.setSelectedTranasctionOverview(selectedOption))
        },
        [dispatch]
    )

    const toggleDatepicker = React.useCallback((value) => {
        setIsDatepickerOpen(value !== undefined ? value : (prev) => !prev)
    }, [])

    useEffect(() => {
        (async () => {
            if (clientData?.clientId) {
                setIsLoading(true)
                try {
                    await fetchTransactionOverview()
                } finally {
                    setIsLoading(false)
                }
            }
        })()
    }, [clientData, selectedDateOption])

    return (
        <div className="jncCard mt-2 px-4 sm:px-6 md:px-8">
           <div className="flex w-full flex-col md:flex-row justify-between items-center mb-4 space-y-3 md:space-y-0">
                <h2 className="font-semibold text-lg sm:text-xl leading-7 text-left w-full md:w-auto">
                    Transaction Overview
                </h2>
                <DatePicker
                    isOpen={isDatepickerOpen}
                    toggle={toggleDatepicker}
                    selectedKey={selectedDateOption.key}
                    onChange={handleDateChange}
                />
            </div>
            {isLoading ? (
                <div className="flex overflow-x-auto sm:overflow-x-hidden sm:flex-wrap -mx-2">
                    {[...Array(4)].map((_, index) => (
                        <SkeletonCard key={index} isLast={index === 3} />
                    ))}
                </div>
            ) : overviewData.length > 0 ? (
                <div className="flex overflow-x-auto sm:overflow-x-hidden sm:flex-wrap -mx-2">
                    {overviewData.map((data) => (
                        <OverviewCard
                            key={data.key}
                            data={data}
                            imageBaseUrl={imageBaseUrl}
                            treadText={selectedDateOption.treadText}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500 mt-4">
                    No data available
                </p>
            )}
        </div>
    )
}

export default TransactionOverview