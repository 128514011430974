import axios from 'axios';

export const getDeviceIP = () => new Promise(async (resolve, reject) => {
  let ipAddress = null
  await axios.get("https://ipinfo.io").then((response) => {
    ipAddress = response.data.ip;
  }).catch((err) => {
    console.error(err);
  })
  if (!ipAddress) {
    await axios.get('https://api.ipify.org?format=json')
      .then(function (response) {
        resolve(response.data.ip)
      })
      .catch(function (error) {
        resolve(null)
      });
  } else {
    resolve(ipAddress)
  }
})


export const removeHttp = (url) => {
  if (url.startsWith('https.')) {
    const https = 'https.';
    return url.slice(https.length);
  }
  if (url.startsWith('http.')) {
    const https = 'http.';
    return url.slice(https.length);
  }
  if (url.startsWith('https://')) {
    const https = 'https://';
    return url.slice(https.length);
  }
  if (url.startsWith('http://')) {
    const https = 'http://';
    return url.slice(https.length);
  }
  if (url.startsWith('www.')) {
    const http = 'www.';
    return url.slice(http.length);
  }

  return url;
}

export const clearStorageData = () => {
  let localDetails = {
    domainNameUrl: localStorage.getItem("domainNameUrl"),
    paymentProviderId: sessionStorage.getItem("paymentProviderId"),
    paymentProviderName: sessionStorage.getItem("paymentProviderName"),
    logoWidth: sessionStorage.getItem("logoWidth"),
    manageCurrencies: localStorage.getItem("manageCurrencies"),
    redirectURL: sessionStorage.getItem("redirectURL"),
    whitelableName: sessionStorage.getItem("whitelableName"),
  }
  localStorage.clear()
  if (localDetails.redirectURL || !localDetails.whitelableName) {
    sessionStorage.clear()
  }
  if (localDetails.manageCurrencies) {
    localStorage.setItem("manageCurrencies", localDetails.manageCurrencies)
  }
  if (localDetails.domainNameUrl) {
    localStorage.setItem("domainNameUrl", localDetails.domainNameUrl)
  }
  if (localDetails.paymentProviderId) {
    sessionStorage.setItem("paymentProviderId", localDetails.paymentProviderId)
  }
  if (localDetails.paymentProviderName) {
    sessionStorage.setItem("paymentProviderName", localDetails.paymentProviderName)
  }
  if (localDetails.logoWidth) {
    sessionStorage.setItem("logoWidth", localDetails.logoWidth)
  }
  sessionStorage.removeItem("whitelabelId")
}