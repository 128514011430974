import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import Popover from '@mui/material/Popover';
import ExcelJS from 'exceljs';
import { format } from 'date-fns'
import { getCurrencySymbol, getExChangeAmount, getTransectionDetails, toFixedTrunc } from '../../../helpers';
import { constant } from '../../constant';

export default function StatementsTableActions(props) {
    const { row, openEditStatementModal, readOnly } = props
    const api_url = useSelector((state) => state.config.api_url)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const activeCurrencyPage = localStorage.getItem("activeCurrencyPage")
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const downloadExcel = (blob, fileName) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    const generateExcel = async (data) => {
        const { fromDate, endDate, currency, transactionDetails, openingBalance, closingBalance } = data;

        const startingDate = format(fromDate, 'dd MMM');
        const endingDate = format(endDate, 'dd MMM');
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('February 2022');

        const addFormattedRow = (rowData, mergeCellsRange = null, isBold = false, alignment = {}) => {
            const row = worksheet.addRow(rowData);
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.alignment = { wrapText: true, ...alignment };
                if (isBold) cell.font = { bold: true };
            });
            if (mergeCellsRange && !worksheet.getCell(mergeCellsRange).isMerged) {
                worksheet.mergeCells(mergeCellsRange);
            }
            return row;
        };

        const setColumns = () => {
            worksheet.columns = [
                { key: 'Date', width: 15 },
                { key: 'Time', width: 15 },
                { key: 'Type', width: 20 },
                { key: 'Details', width: 25 },
                { key: 'Reference', width: 25 },
                { key: 'Amount', width: 25 },
                { key: 'Balance', width: 25 },
            ];
        };

        addFormattedRow([`${currency} Statement`], 'A1:G1', true, { vertical: 'top', horizontal: 'left' });
        addFormattedRow(['Date', 'Time', 'Type', 'Details', 'Reference', 'Amount', 'Balance'], null, true);
        if (transactionDetails.length === 0) {
            addFormattedRow([startingDate, '', '', 'Statement Opening Balance', '', '', `${getCurrencySymbol(currency)} ${toFixedTrunc(openingBalance, activeCurrencyPage === 'crypto' ? 6 : 2)}`]);
            if (!worksheet.getCell('A3').isMerged) {
                worksheet.mergeCells('A3:G3');
            }
            const noDataCell = worksheet.getCell('A3');
            noDataCell.value = 'No transactions available for this date range';
            noDataCell.alignment = { horizontal: 'center', vertical: 'middle' };
            noDataCell.font = { bold: true };

            addFormattedRow([endingDate, '', '', 'Statement Closing Balance', '', '', `${getCurrencySymbol(currency)} ${toFixedTrunc(closingBalance, activeCurrencyPage === 'crypto' ? 6 : 2)}`]);
        } else {
            addFormattedRow([startingDate, '', '', 'Statement Opening Balance', '', '', `${getCurrencySymbol(currency)} ${toFixedTrunc(openingBalance, activeCurrencyPage === 'crypto' ? 6 : 2)}`]);
            transactionDetails
                .filter(row => row)
                .forEach(row => {
                    const feeText =
                        row?.type === 'FX' || row?.currencyType === "crypto"
                            ? ''
                            : `Fee : ${getCurrencySymbol(currency)} ${toFixedTrunc(row?.transactionRef?.transactionFee || row?.transactionRef?.transferInFee) || 0.0}`;
                    const getTransactionType = (row, currencyType) => {
                        const { type, transactionRef } = row;
                        if (type === constant.TRANSACTION_TYPE_CONSTANT.FIAT.TRANSFER) {
                            return `Transfer (${transactionRef?.transferInOut || ""})`;
                        }
                        if (currencyType === "fiat") {
                            if (type === constant.TRANSACTION_TYPE_CONSTANT.CRYPTO.BUY) return "Transfer (Out)";
                            if (type === constant.TRANSACTION_TYPE_CONSTANT.CRYPTO.SELL) return "Transfer (In)";
                        }
                        return type || "N/A";
                    };
                    const newRow = worksheet.addRow([
                        format(row.creationDate, 'dd MMM'),
                        new Date(row.creationDate).toLocaleTimeString(),
                        `${getTransactionType(row, activeCurrencyPage)}${feeText ? `\n${feeText}` : ''}`,
                        getTransectionDetails(row, activeCurrencyPage),
                        row.transactionRef?.transactionDetails[0]?.reference || "N/A",
                        getExChangeAmount(row, activeCurrencyPage),
                        `${getCurrencySymbol(currency)} ${toFixedTrunc(row.balanceAmount, activeCurrencyPage === 'crypto' ? 6 : 2)}`
                    ]);
                    newRow.eachCell({ includeEmpty: true }, (cell) => {
                        cell.alignment = { wrapText: true };
                    });
                });

            addFormattedRow([endingDate, '', '', 'Statement Closing Balance', '', '', `${getCurrencySymbol(currency)} ${toFixedTrunc(closingBalance, activeCurrencyPage === 'crypto' ? 6 : 2)}`]);
        }
        setColumns();
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadExcel(blob, 'Account_Statement.xlsx');
    };

    const onDownLoad = async (row, downloadType = "pdf") => {
        handleClose()
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/generate-statement-pdf',
            {
                statementId: row._id,
                fromDate: row.timePeriod.fromDate || row.timePeriod.formDate,
                endDate: row.timePeriod.endDate,
                clientId: row.clientId,
                type: row.type,
                currency: row.currency,
                activeCurrencyPage,
                downloadType
            },
            async (data) => {
                if (data) {
                    if (downloadType === 'pdf') {
                        const pdfData = data
                        const newTab = window.open()
                        newTab.document.write(pdfData)
                    } else {
                        await generateExcel(data)
                    }
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    return (
        <React.Fragment>
            <div className="jncTable-actions">
                <button
                    onClick={() => onDownLoad(row)}
                    className="jncTable-actions-btn"
                >
                    <span data-e2e="view-btn">View</span>
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none">
                        <path
                            d="M2.01677 7.5944C1.90328 7.4147 1.84654 7.32485 1.81477 7.18626C1.79091 7.08216 1.79091 6.918 1.81477 6.8139C1.84654 6.67532 1.90328 6.58547 2.01677 6.40577C2.95461 4.92078 5.74617 1.16675 10.0003 1.16675C14.2545 1.16675 17.0461 4.92078 17.9839 6.40577C18.0974 6.58547 18.1541 6.67532 18.1859 6.8139C18.2098 6.918 18.2098 7.08216 18.1859 7.18626C18.1541 7.32485 18.0974 7.4147 17.9839 7.5944C17.0461 9.07938 14.2545 12.8334 10.0003 12.8334C5.74617 12.8334 2.95461 9.07938 2.01677 7.5944Z"
                            stroke="#344054"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.0003 9.50008C11.381 9.50008 12.5003 8.38079 12.5003 7.00008C12.5003 5.61937 11.381 4.50008 10.0003 4.50008C8.61962 4.50008 7.50034 5.61937 7.50034 7.00008C7.50034 8.38079 8.61962 9.50008 10.0003 9.50008Z"
                            stroke="#344054"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
                <div>
                    <button
                        onClick={handleClick}
                        className="jncTable-actions-btn"
                    >
                        <span data-e2e="download-btn">Download</span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path
                                d="M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 7.33333L9 11.5M9 11.5L4.83333 7.33333M9 11.5V1.5"
                                stroke="#344054"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="p-2 rounded-lg">
                            <button className='flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C]' onClick={() => onDownLoad(row)}>
                                <img src={`${imageBaseUrl}/adminUi/pdf.svg`} className='w-6 h-6' alt="download" />
                                PDF file</button>
                            <button className='flex justify-between items-center gap-3 px-3 text-sm font-medium text-[#51525C] mt-2'
                                onClick={() => onDownLoad(row, "excel")}
                            >
                                <img src={`${imageBaseUrl}/adminUi/excel.svg`} className='w-6 h-6' alt="download" />
                                Excel file</button>
                        </div>
                    </Popover>
                </div>
                <button
                    onClick={() => openEditStatementModal(row)}
                    className={`${readOnly && 'jnc-btn-disable'} jncTable-actions-btn`}
                    disabled={readOnly}
                >
                    <span data-e2e="edit-btn">Edit</span>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M13.9999 7.33326L10.6666 3.99993M1.08325 16.9166L3.90356 16.6032C4.24813 16.5649 4.42042 16.5458 4.58146 16.4937C4.72433 16.4474 4.86029 16.3821 4.98566 16.2994C5.12696 16.2062 5.24954 16.0836 5.49469 15.8385L16.4999 4.83326C17.4204 3.91279 17.4204 2.4204 16.4999 1.49993C15.5795 0.579452 14.0871 0.579451 13.1666 1.49992L2.16136 12.5052C1.91621 12.7503 1.79363 12.8729 1.70045 13.0142C1.61778 13.1396 1.55243 13.2755 1.50618 13.4184C1.45405 13.5794 1.43491 13.7517 1.39662 14.0963L1.08325 16.9166Z"
                            stroke="#344054"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </React.Fragment>
    )
}
