import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import JncDialogTitle from '../../../../components/modals/JncDialogTitle'
import { apiService } from '../../../../../../common/apiCallService'
import { useSelector } from 'react-redux'
import { useJunoCommonFunctions } from '../../../../../helpers'
import BalanceFiatTransfer from './BalanceFiatTransfer'

const MerchantTransfer = (props) => {
    const { open, onClose } = props
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const [merchants, setMerchants] = useState([])
    const [selectedMerchant, setSelectedMerchant] = useState(null)
    const [transferModal, settransferModal] = useState(false)
    const [searchText, setSearchText] = useState("")

    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    const handleOnClose = () => {
        onClose()
    }

    const fetchMerchants = () => {
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/fetch-merchant',
            {
                clientObjId: clientData?._id,
                clientId: clientData?.clientId,
                onlyLinked: true,
                page: 'all',
            },
            async (data) => {
                if (data) {
                    setMerchants(data.merchants)
                }
            },
            (err) => {
                console.log(err.responseJSON.err.message)
            }
        )
    }

    useEffect(() => {
        if (open) {
            fetchMerchants()
        }
    }, [open])

    return (
        <>
            <Dialog
                className={`jncModal merchant-modal`}
                onClose={handleOnClose}
                open={open}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                fullScreen={windowWidth < 768}
            >
                <div className="max-h-[600px]">
                    <JncDialogTitle title={'Merchants'} onClose={onClose} />
                    <p className="font-normal text-base text-[#51525C] px-6">
                        To transfer funds to a merchant, please select one from the
                        list below.
                    </p>

                    <div className="p-6">
                        <input
                            type="text"
                            className="rounded-lg w-full p-2 border"
                            placeholder="Search Merchants"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="">
                            {merchants.length > 0 ? (
                                merchants.filter((merchant) => merchant.merchantName.includes(searchText)).map((item, index) => (
                                    <div
                                        className="flex items-center gap-3 rounded-lg my-3 p-4 border border-[#EAECF0] cursor-pointer"
                                        key={index}
                                        onClick={() => {
                                            setSelectedMerchant(item)
                                            settransferModal(true)
                                            handleOnClose()
                                        }}
                                    >
                                        <img
                                            src={item.merchantLogo}
                                            alt="merchantLogo"
                                            width={44}
                                            height={44}
                                            className="rounded-full"
                                        />
                                        <h1 className="" data-e2e={item.merchantName}>{item.merchantName}</h1>
                                    </div>
                                ))
                            ) : (
                                <div className="my-3 p-4 border border-[#EAECF0] rounded-lg text-center">
                                    No merchants found.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <BalanceFiatTransfer
                open={transferModal}
                onClose={() => {
                    settransferModal(false)
                }}
                row={{}}
                selectCurrency={true}
                selectedMerchant={selectedMerchant}
            />
        </>
    )
}

export default MerchantTransfer
