import React from 'react'
import JunoHeroCard from '../components/JunoHeroCard'
import { useSelector } from 'react-redux'

export default function JunoCard() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)

    const heroCardDetails = [
        {
            id: '01',
            topTitle: 'Instant transfers',
            bottomText: 'Send funds to other Juno Money users instantly.',
            subImage: `${imageBaseUrl}/landingpage/JunoCardInstantPayment.svg`,
            bgColor: '#F4F4F5',
        },
        {
            id: '02',
            topTitle: 'Exchange in 30+ currencies',
            bottomText:
                'Exchange in multiple currencies including GBP, EUR, USD, CAD, TRY, CHF, AED and many more.',
            subImage: `${imageBaseUrl}/landingpage/JunoCardExchange.svg`,
            bgColor: '#3D57FA',
            textColor: 'white',
        },
        {
            id: '03',
            topTitle: 'Fast account creation',
            bottomText:
                'Onboarding for an individual account is quick and seamless.',
            bgColor: '#26272B',
            textColor: 'white',
        },
        {
            id: '04',
            topTitle: 'Dedicated account manager',
            bottomText:
                'Dedicated account manager to support you with your Juno Money journey.',
            bgColor: '#F4F4F5',
        },
        {
            id: '05',
            topTitle: 'Withdraw',
            subTitle:
                'Withdraw your funds utilising our global banking networks.',
            imageDiv: <div className="w-full">
                <img
                    src={`${imageBaseUrl}/landingpage/JunoWithdrawalSender.svg`}
                    alt="Juno Withdrawal Sender"
                    className="w-2/5 h-auto float-left"
                />
                <div className="flex justify-center w-full h-full">
                    <img
                        src={`${imageBaseUrl}/landingpage/JunoWithdrawalConnect.svg`}
                        alt="Juno Withdrawal Connector"
                        className="w-4/5 h-3/5"
                    />
                </div>
                <img
                    src={`${imageBaseUrl}/landingpage/JunoWithdrawalReceiver.svg`}
                    alt="Juno Withdrawal Receiver"
                    className="w-2/5 h-auto float-right"
                />
            </div>
            ,
            bgColor: '#F4F4F5',
        },
        {
            id: '06',
            topTitle: 'Accept payments',
            subTitle:
                'Accept payments into your account from around the world.',
            image: `${imageBaseUrl}/landingpage/JunoCardPayments.svg`,
            bgColor: '#3D57FA',
            textColor: 'white',
        },
    ]

    return (
        <div className="max-w-[1280px] mx-auto !px-[12px] !py-[40px] md:!px-[24px] md:!py-[48px] lg:!px-[80px] lg:!pb-[100px] lg:!pt-[60px] xl:!px-0">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[12px]">
                {heroCardDetails.map((details, index) => (
                    <JunoHeroCard key={index} cardDetails={details} />
                ))}
            </div>
        </div>
    )
}


