import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { format } from 'date-fns'
//todo:kris delete import { cryptoCurrencyList } from '../../../helpers';
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import JncDialogTitle from '../../components/modals/JncDialogTitle'
import { DialogActions, DialogContent } from '@mui/material'
import JncMonthPicker from '../../components/global/JncMonthPicker'
import JncButton from '../../components/global/JncButton'
import JncCurrenciesTypeahead from '../../components/global/JncCurrenciesTypeahead'
import { useJunoCommonFunctions } from '../../../helpers'

const JncStatementsEditModal = ({
    isEdit,
    editStatementData,
    updateStatements,
    onClose,
    open,
    fullScreen,
}) => {
    const clientData = useSelector((state) => state.juno.clientData)
    const api_url = useSelector((state) => state.config.api_url)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const [isLoading, setIsLoading] = useState(false)
    const [reqError, setReqError] = useState('')
    const createInitialState = {
        name: '',
        fromDate: new Date(),
        endDate: '',
        currency: [],
    }
    const [data, setData] = useState({})
    const [formattedDate, setFormattedDate] = useState(null)
    const [currencySelection, setCurrencySelection] = useState([])
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    useEffect(() => {
        if (!isEdit) {
            setData(createInitialState)
            setFormattedDate(format(createInitialState.fromDate, 'yyyy-MM'))
            setCurrencySelection([])
        } else {
            const editInitialState = {
                statementId: editStatementData._id,
                fromDate:
                    editStatementData.timePeriod &&
                        editStatementData.timePeriod.formDate
                        ? new Date(editStatementData.timePeriod.formDate)
                        : new Date(editStatementData.timePeriod.fromDate),
                endDate: new Date(editStatementData.timePeriod.endDate),
                currency: [editStatementData.currency],
            }
            setData(editInitialState)
            setFormattedDate(format(editInitialState.fromDate, 'yyyy-MM'))
            setCurrencySelection(editInitialState.currency)
        }
    }, [open])

    function onChangeCurrency(val) {
        // if (!isEdit && val[0]) {
        setReqError(null)
        // }
        setCurrencySelection(val)
        setData({ ...data, currency: val })
    }

    function onChangeDate(value) {
        setFormattedDate(format(value, 'yyyy-MM'))
        setData({ ...data, fromDate: value })
    }

    function onSubmitSuccess() {
        setIsLoading(false)
        onClose()
        updateStatements()
    }

    function handleOnClose() {
        onClose()
        setReqError('')
    }

    const isFormValid = currencySelection.length > 0 && currencySelection[0]
    const onSubmit = async (e) => {
        e.preventDefault()
        if (!isFormValid) {
            setReqError('* Currency field is required')
        }
        if (isFormValid) {
            setIsLoading(true)
            if (!isEdit) {
                apiService(
                    (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/generate-client-statement',
                    {
                        clientId: clientData.clientId,
                        ...data,
                        fromDate: format(data.fromDate, 'yyyy-MM'),
                        currency: data.currency[0],
                        currencyType: activeCurrencyPage
                    },
                    async (data) => {
                        if (data) {
                            onSubmitSuccess()
                        }
                    },
                    (err) => {
                        setReqError(err?.responseJSON?.err?.message || err)
                        setIsLoading(false)
                    },
                )
            } else {
                apiService(
                    (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/update-client-statement',
                    {
                        clientId: clientData.clientId,
                        ...data,
                        fromDate: format(data.fromDate, 'yyyy-MM'),
                        currency: data.currency[0],
                        currencyType: activeCurrencyPage
                    },
                    async (data) => {
                        if (data) {
                            onSubmitSuccess()
                        }
                    },
                    (err) => {
                        setReqError(err?.responseJSON?.err?.message || err)
                        setIsLoading(false)
                    },
                )
            }
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen && windowWidth < 768}
            className="jncModal jncStatementsModal"
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            onClose={handleOnClose}
            open={open}
        >
            <JncDialogTitle
                onClose={handleOnClose}
                title={isEdit ? 'Edit statement' : 'New statement'}
            />
            <DialogContent dividers>
                <div className="jncModal__body">
                    <form>
                        <div className="jncModal__field mb">
                            <p className="jncModal__label">
                                Statement time Period
                            </p>
                            <JncMonthPicker
                                onChangeDate={onChangeDate}
                                selected={data.fromDate}
                                formattedDate={formattedDate}
                            />
                        </div>
                        <JncCurrenciesTypeahead
                            isAllCurrenciesAllowed={true}
                            onChange={onChangeCurrency}
                            selected={currencySelection}
                        />
                        {reqError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                                data-e2e="error"
                                data-e2e-msg={reqError}
                            >
                                {reqError}
                            </div>
                        )}
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="w-100 jncModal__btns justify-end grid-on-mobile">
                    <JncButton
                        dataE2e="cancel"
                        onClickCall={onClose}
                        text="Cancel"
                        isOutlinedStyle={true}
                    />
                    <JncButton
                        dataE2e="generate"
                        loading={isLoading}
                        onClickCall={onSubmit}
                        text="Generate"
                    />
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default JncStatementsEditModal
