import React, { useState } from 'react'
import { getStatus } from '../../../helpers'
import InputCopyButton from '../../components/global/InputCopyButton'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

import BalanceFiatTransfer from '../balances/actions/fiat/BalanceFiatTransfer'


export default function TransactionMobileDialogTransfer({ data }) {
    const details = data && data.transactionDetails[0]

    const [repeatModal, setRepeatModal] = useState('')


    const transactionFee = data && data.transactionFee
    const balanceBeforeDeduction = data.transactionFee
        ? Number(details.amount) + Number(data.transactionFee)
        : details.amount


    return (
        <div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Status:</p>
                <div className="d-flex">
                    <div
                        className={`jncTransactionsList__status ${getStatus(
                            data.status.code
                        )
                            .toLowerCase()
                            .replace('/', '')}`}
                    >
                        {getStatus(data.status.code)}
                    </div>
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Id:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {data.transactionId || '-'}
                    </p>
                    <InputCopyButton copyValue={data.transactionId} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Juno Money Email:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.email || '-'}
                    </p>
                    <InputCopyButton copyValue={details.email} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    {!transactionFee ? 'Amount' : 'Amount before deduction'}:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(balanceBeforeDeduction, 2) || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={`$ ${toFixedTrunc(balanceBeforeDeduction, 2)}`}
                    />
                </div>
            </div>
            {transactionFee ? (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Amount after deduction :
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(data.currency)}{' '}
                            {toFixedTrunc(details.amount, 2) || '-'}
                        </p>
                        <InputCopyButton
                            copyValue={`$ ${toFixedTrunc(
                                details.amount,
                                2
                            )}`}
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Fee:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(transactionFee, 2) || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={`$ ${toFixedTrunc(transactionFee, 2)}`}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Juno Money Account Name:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.accountName || '-'}
                    </p>
                    <InputCopyButton copyValue={details.accountName} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Reference:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.reference || '-'}
                    </p>
                    <InputCopyButton copyValue={details.reference} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Balance after transaction:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(data.balanceAfterTransaction, 2)}
                    </p>
                    <InputCopyButton copyValue={data.balanceAfterTransaction} />
                </div>
            </div>
            {ErrorTransactions.includes(data.status.code) && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text !text-[#D92D20] ">
                        {getStatus(data.status.code)}:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.status.message}
                        </p>
                        <InputCopyButton copyValue={data.status.message} />
                    </div>
                </div>
            )}

            <BalanceFiatTransfer
                open={repeatModal === 'Transfer'}
                onClose={() => setRepeatModal('')}
                selectCurrency={true}
            />
        </div>
    )
}
