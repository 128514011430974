import React from 'react'
import RegistrationLayout from '../../client/layouts/RegistrationLayout'
import { useSelector } from 'react-redux'
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { useJunoCommonFunctions } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import JunoLogin from '../components/global/JunoLogin'

export default function JunoRegistrationPage() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const { useWindowWidth } = useJunoCommonFunctions();
    const windowWidth = useWindowWidth();
    const navigate = useNavigate()
    const [showLoginModal, setShowLoginModal] = useState(false)

    return (
        <RegistrationLayout>
            <div className="font-inter flex flex-col md:gap-8 md:items-center md:justify-center bg-white w-full h-full md:w-[644px] md:h-auto py-8 px-6 md:px-8 rounded-lg">
                <img
                    className="hidden md:block w-36"
                    src={`${imageBaseUrl}/landingpage/dark-logo.svg`}
                    alt="logo"
                />
                <div className='hidden md:block'>
                    <h2 className='font-semibold text-4xl font-inter text-brand-black'>Create Your Account</h2>
                    <p className='!mt-3 text-base text-[#70707B] font-inter'>What kind of account would you like to open today?</p>
                </div>
                <div className='flex flex-col md:flex-row gap-4 md:gap-6'>
                    <div onClick={() => navigate("/invite-to-register/personal")} className='px-10 py-8 md:px-10 md:py-[30px] w-full h-[100px] md:w-[278px] md:h-[254px] rounded-xl bg-[#F4F4F5] flex  md:flex-col items-center gap-6 md:gap-0 md:justify-between hover:bg-[#d1d1d6] group hover:cursor-pointer'>
                        <div className='md:p-[14px] group-hover:bg-[#e4e4e7] rounded-xl'>
                            <PersonIcon style={{ fontSize: windowWidth < 768 ? 32 : 52, color: "black" }} />
                        </div>
                        <div className='md:text-center'>
                            <p className='md:text-xl font-semibold text-brand-black font-inter cursor-pointer group-hover:underline'>Personal account</p>
                            <p className='!mt-2 text-[#51525C] font-inter text-sm sm:text-base'>Send, spend and receive around the world.</p>
                        </div>
                    </div>
                    <div onClick={() => navigate("/invite-to-register/business")} className='px-10 py-8 md:px-10 md:py-[30px] w-full h-[100px] md:w-[278px] md:h-[254px] rounded-xl bg-[#F4F4F5] flex  md:flex-col items-center gap-6 md:gap-0 md:justify-between hover:bg-[#d1d1d6] group hover:cursor-pointer'>
                        <div className='md:p-[14px] group-hover:bg-[#e4e4e7] rounded-xl'>
                            <WorkIcon style={{ fontSize: windowWidth < 768 ? 32 : 52, color: "black" }} />
                        </div>
                        <div className='md:text-center'>
                            <p className='md:text-xl font-semibold text-brand-black font-inter cursor-pointer group-hover:underline'>Business account</p>
                            <p className='!mt-2 text-[#51525C] font-inter text-sm sm:text-base'>Do business or freelance work internationally</p>
                        </div>
                    </div>


                </div>
                <div className="jncAuth__underBtn">
                    Already have an account?&nbsp;
                    <button
                        type="button"
                        className="jncAuth__link"
                        onClick={() => {
                            setShowLoginModal(true)
                        }}
                    >
                        Log In
                    </button>
                </div>
                <p className='!mt-7 text-base text-center text-[#70707B] font-inter md:hidden'>What kind of account would you like to open today?</p>
            </div>
            {showLoginModal && (
                <JunoLogin
                    show={true}
                    closeModal={() => setShowLoginModal(false)}
                />
            )}
        </RegistrationLayout>
    )
}
