import React, { useState, useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import JncPageTitle from '../components/global/JncPageTitle'
import JncProfile from '../components/profile/JncProfile'
import { useSelector, useDispatch } from 'react-redux'
import { setClientData } from '../../../Redux/actions'
import { apiService } from '../../../common/apiCallService'
import JncStatements from '../tables/statements/JncStatements'
import { useJunoCommonFunctions } from '../../helpers'
import JncAdditionalUser from '../tables/AdditionalUser/JncAdditionalUser'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'

export default function JncProfilePage() {
    const dispatch = useDispatch()
    const clientData = useSelector((state) => state.juno.clientData)
    const api_url = useSelector((state) => state.config.api_url)
    const { getBalances, useWindowWidth, logout } = useJunoCommonFunctions()
    const [activeTab, setActiveTab] = useState('')
    const [headerAction, setHeaderAction] = useState(false)

    useEffect(() => {
        if (!clientData) {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-logedIn-client',
                {},
                async (data) => {
                    if (data) {
                        dispatch(setClientData(data))
                        if (data?.isDeleted || data?.onboardingStatus === 'Rejected' || data?.status !== 'Active') {
                            logout()
                        }
                    }
                },
                (err) => {
                    console.log(err)
                }
            )
        }
        if (clientData) {
            getBalances()
        }
    }, [clientData])

    const resetTab = () => setActiveTab('')

    const windowWidth = useWindowWidth()

    return (
        <JncLayout>
            <JunoClientSnackbar />
            <div className="jncPage">
                {!activeTab && (
                    <JncPageTitle
                        title="Profile"
                        backLink="/client"
                        resetTab={resetTab}
                    />
                )}

                {activeTab && activeTab !== 'Personal Information' && (
                    <JncPageTitle
                        headerBtn="Add"
                        headerBtnAction={() => setHeaderAction(true)}
                        activeTab={activeTab}
                        resetTab={() => {
                            setHeaderAction(false)
                            setActiveTab('')
                        }}
                    />
                )}
                <div className="jncProfile">
                    <JncProfile
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />
                    {clientData &&
                        clientData.accountRole === 'client' &&
                        (windowWidth > 767 ||
                            (activeTab === 'Additional Users' &&
                                windowWidth < 768)) ? (
                        <JncAdditionalUser
                            showTablePagination={true}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            headerAction={headerAction}
                        />
                    ) : null}
                    {windowWidth > 767 ||
                        (activeTab === 'Statements' && windowWidth < 768) ? (
                        <JncStatements
                            showTablePagination={true}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            headerAction={headerAction}
                        />
                    ) : null}
                </div>
            </div>
        </JncLayout>
    )
}
