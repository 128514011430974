import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Icon from '../../icons/Icon'
import { toFixedTrunc, getStatus, getCryptoStatus, useJunoCommonFunctions, getBalanceSign, getCurrencySymbol, RenderTransactionStatusBadge } from '../../../helpers'
import format from 'date-fns/format'
import Pagination from '../../components/pagination/Pagination'
import JncLoading from '../../components/pagination/JncLoading'
import JncEmptyData from '../../components/table/JncEmptyData'

const PageSize = 5

export default function TransactionsMobile({
    filteredData,
    isLoading,
    noDataText,
    showTablePagination,
    openTransactionDialog,
    totalRows,
    handlePageChange,
}) {
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const { getCryptoIcons } = useJunoCommonFunctions()


    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [currentPage])

    useEffect(() => {
        const firstPageIndex = 0
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [filteredData])

    const handlePaginationChange = (page) => {
        if (handlePageChange) {
            handlePageChange(page)
        }
        setCurrentPage(page)
    }

    return (
        <div>
            {isLoading ? (
                <JncLoading />
            ) : (
                <div>
                    {paginationData.length < 1 && (
                        <JncEmptyData text={noDataText} />
                    )}
                    <div className="jncTransactionsList">
                        {paginationData.map((transaction) => {
                            const depositAmount =
                                transaction.type === 'Deposit' &&
                                transaction.transactionDetails[0].amount -
                                    transaction.transactionFee
                            return (
                                <button
                                    onClick={() => openTransactionDialog(transaction)}
                                    type="button"
                                    key={transaction.createdDate}
                                    className="jncTransactionsList__item"
                                >
                                    <div className="jncTransactionsList__type">
                                        <div className="jncTransactionsList__type-icon">
                                            {transaction.currencyType === 'fiat'
                                                ? <Icon id={_.camelCase(transaction.type)} />
                                                : getCryptoIcons(transaction.type)
                                            }
                                        </div>
                                        <div className="">
                                            <p className="jncTransactionsList__text">
                                                {transaction.type}
                                            </p>
                                            <span className="jncTransactionsList__date">
                                                {format(
                                                    new Date(
                                                        transaction.createdDate.toString()
                                                    ),
                                                    'dd.MM.y'
                                                )}
                                                &nbsp;
                                                {format(
                                                    new Date(
                                                        transaction.createdDate.toString()
                                                    ),
                                                    'HH:mm'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {transaction.currencyType === 'fiat' ? (
                                        <div>
                                            {transaction.type == 'FX' && (
                                                <p className="jncTransactionsList__text t-right">
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .fromCurrency
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        transaction.transactionDetails[0]
                                                            .fromAmount,
                                                        2
                                                    )}
                                                    &ensp;-&ensp;
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .toCurrency
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        transaction.transactionDetails[0]
                                                            .toAmount,
                                                        2
                                                    )}
                                                </p>
                                            )}
                                            {transaction.type != 'FX' && (
                                                <p className="jncTransactionsList__text t-right">
                                                    {getBalanceSign(transaction)}{' '}
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .currency
                                                    )}{' '}
                                                    {transaction.type !== 'Deposit'
                                                        ? toFixedTrunc(
                                                              transaction
                                                                  .transactionDetails[0]
                                                                  .amount,
                                                              2
                                                          )
                                                        : toFixedTrunc(
                                                              depositAmount,
                                                              2
                                                          )}
                                                </p>
                                            )}
                                            {<RenderTransactionStatusBadge status={transaction?.status} type={transaction?.type} currencyType={transaction?.currencyType} className="jncTransactionsList__status" />}
                                        </div>
                                    ) : (
                                        <div>
                                            {transaction.type == 'Exchange' && (
                                                <p className="jncTransactionsList__text t-right">
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .fromCryptoId
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        transaction.transactionDetails[0]
                                                            .fromCryptoAmount,
                                                        6
                                                    )}{' '}
                                                    &ensp;-&ensp;
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .toCryptoId
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        transaction.transactionDetails[0]
                                                            .toCryptoAmount,
                                                        6
                                                    )}{' '}
                                                </p>
                                            )}
                                            {transaction.type != 'Exchange' && (
                                                <p className="jncTransactionsList__text t-right">
                                                    {getBalanceSign(transaction)}{' '}
                                                    {getCurrencySymbol(
                                                        transaction.currency ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .currency
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        transaction.transactionDetails[0]
                                                            .cryptoAmount ||
                                                            transaction
                                                                .transactionDetails[0]
                                                                .amount,
                                                        6
                                                    )}
                                                </p>
                                            )}
                                            {<RenderTransactionStatusBadge status={transaction?.status} type={transaction?.type} currencyType={transaction?.currencyType} className="jncTransactionsList__status" />}
                                        </div>
                                    )}
                                </button>
                            )
                        })}
                    </div>
                    {showTablePagination && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalRows || filteredData.length}
                            pageSize={PageSize}
                            onPageChange={handlePaginationChange}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
